import { GatsbySeo } from 'gatsby-plugin-next-seo'
import AppPage from 'src/components/AppPage/AppPage'

function App() {
  return (
    <>
      <GatsbySeo title="Aplicativo | Decathlon" />
      <AppPage />
    </>
  )
}

export default App
